<template>
    <div id="app">
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>
<script>
import config from '@/config'
export default {
    data() {
        return {}
    },
    created() {},
    mounted() {
        this.goOrther()
        window.onresize = () => {
            return (() => {
                if (document.documentElement.clientWidth > 900) {
                    let baseurl = config.baseUrl // 测试接口
                    //  利用方法跳转到对应的页面
                    window.location.href = baseurl
                }
            })()
        }
    },
    //注销window.onresize事件
    destroyed() {
        window.onresize = null
    },
    methods: {
        goOrther() {
            var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null
            if (is_mobi) {
                // 如果是移动端就不用跳转到其他页面
            } else if (!is_mobi && document.documentElement.clientWidth < 900) {
            } else {
                // 如果是pc 端就跳转到其他页面
                let baseurl = config.baseUrl // 测试接口
                //  利用方法跳转到对应的页面
                window.location.href = baseurl
            }
        }
    }
}
</script>
<style lang="less">
@import 'assets/css/base.css';
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//引入网络请求的方法
import api from '@/api/index'
Vue.prototype.$api = api

//导入字体图标文件
import '@/assets/iconfont/iconfont.css'

//引入element-ui
import '@/vant/index.js'

//全局引入滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// import VConsole from 'vconsole'
// new VConsole()

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

const Welcome = () => import('../views/welcome/Welcome')
const Home = () => import('../views/home/Home')
const HomePage = () => import('../views/homepage/HomePage')
const Contact = () => import('../views/contact/Contact')
const News = () => import('../views/news/News')
const NewsDetail = () => import('../views/news/childComps/NewsDetail')
const Activity = () => import('../views/activity/Activity')
const ActivityDetail = () => import('../views/activity/childComps/ActivityDetail')
const Product = () => import('../views/product/Product')


Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/home',
    }, {
        path: '/welcome',
        name: 'welcome',
        component: Welcome,
    }, {
        path: '/home',
        name: 'home',
        component: Home,
        redirect: '/homepage',
        children: [{
            path: '/homepage',
            name: 'homepage',
            component: HomePage
        }, {
            path: '/contact',
            name: 'contact',
            component: Contact,
        }, {
            path: '/news',
            name: 'news',
            component: News,
        }, {
            path: '/news/newsdetail',
            name: 'newsdetail',
            component: NewsDetail,
        }, {
            path: '/activity',
            name: 'activity',
            component: Activity,
        }, {
            path: '/activity/activitydetail',
            name: 'activitydetail',
            component: ActivityDetail,
        }, {
            path: '/product',
            name: 'product',
            component: Product,
        }]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
//挂载路由导航守卫
router.beforeEach((to, form, next) => {
    if (to.path == '/homepage') {
        // 为vuex里的导航索引值重新赋值
        store.commit("changePageStatus", 'home');
    } else {
        // 为vuex里的导航索引值重新赋值
        store.commit("changePageStatus", 'other');
    }
    next()
})
router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})
export default router

const env = {
    // 开发环境
    dev: {
        ApiUrl: 'http://localhost.yuetustar.com/home/api',
        htmlUrl: 'http://admin.178read.com',
        baseUrl: 'http://192.168.1.160:8080',
    },
    // 测试环境
    test: {
        ApiUrl: 'http://admin.178read.com/home/api',
        htmlUrl: 'http://admin.178read.com',
        baseUrl: 'http://178read.com',
    },
    // 生产环境
    pro: {
        ApiUrl: 'https://m.yuetustar.com/home/api',
        htmlUrl: 'https://m.yuetustar.com',
        baseUrl: 'https://yuetustar.com',
    },
}
module.exports = {
    ...env.pro
}
import Vue from 'vue'
// 按需引入：index.css 基础样式必须引入，其它组件样式按需引入
import 'vant/lib/index.css'
 
import {
    Toast,
    Swipe,
    SwipeItem,
    Step,
    Steps,
    Collapse,
    CollapseItem,
} from 'vant';//从vant-ui中引入按钮

Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Collapse);
Vue.use(CollapseItem);

import { request } from "./request";

function getBanner(data) {
    return request({
        url: '/getBannerM',
        method: 'GET',
        data
    })
}
function getMainData(data) {
    return request({
        url: '/getMainData',
        method: 'GET',
        data
    })
}
function getGroupData(data) {
    return request({
        url: '/getGroupData',
        method: 'GET',
        data
    })
}
function getBrandActList(data) {
    return request({
        url: '/getBrandActList',
        method: 'GET',
        params: data
    })
}
function getJoinUsList(data) {
    return request({
        url: '/getJoinUsList',
        method: 'GET',
        params: data
    })
}
function getContact(data) {
    return request({
        url: '/getcontactM',
        method: 'GET',
        params: data
    })
}
function getNewsList(data) {
    return request({
        url: '/getNewsList',
        method: 'GET',
        params: data
    })
}
function getNewsData(data) {
    return request({
        url: '/getNewsData',
        method: 'GET',
        params: data
    })
}
function getProductList(data) {
    return request({
        url: '/getProductList',
        method: 'GET',
        params: data
    })
}
function getProductData(data) {
    return request({
        url: '/getProductData',
        method: 'GET',
        params: data
    })
}
function getBrandActInfo(data) {
    return request({
        url: '/getBrandActInfo',
        method: 'GET',
        params: data
    })
}
function getRecruitList(data) {
    return request({
        url: '/getRecruitList',
        method: 'GET',
        params: data
    })
}
function getBannerList(data) {
    return request({
        url: '/getBanner',
        method: 'GET',
        params: data
    })
}
function getJSSDKSignAPI(data) {
    return request({
        url: '/weixinShare',
        method: 'GET',
        params: data
    })
}
export default {
    getBanner,
    getMainData,
    getGroupData,
    getBrandActList,
    getJoinUsList,
    getContact,
    getNewsList,
    getNewsData,
    getProductList,
    getProductData,
    getBrandActInfo,
    getRecruitList,
    getBannerList,
    getJSSDKSignAPI,
}

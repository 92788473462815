import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 主页导航状态，home表示当前处于主页，other表示其他子页面
    pageStatus: 'home'
  },
  getters: {
  },
  mutations: {
    // 主页导航状态
    changePageStatus(state,status) {
      state.pageStatus = status
    },
  },
  actions: {
  },
  modules: {
  }
})
